#login__container {
    width: 100%;
    height: 100vh;
    background-color: #171717;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 40px;
}

#login__container p {
    color: white;
}

#login__form {
    /*Form*/
    height: 500px;
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all .3s;
  }
  
  #login__form > * {
    /*Form*/
    margin-top: 10px;
  }
  
  #login__form label {
    /*Form*/
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
  
    width: 100%;
  }
  
  #login__form label > * {
    /*Form*/
    margin-top: 5px;
  }
  #login__form button {
    width: 100%;
  }

  h1 {
      color: white;
      font-size: 5em;
  }

  @media screen and (max-width: 1366px)
  {
    h1 {
      margin-top: 10vh;
    }
  }

  @media screen and (max-width: 800px)
  {
    #login__form {
      width: 80%;
    }
  }