#cadastroDeTools__section {
  min-height: 20vh;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cadastroDeTools__container {

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 50px;
  padding-right: 50px;
  border-radius: 4px;

  background-color: #262626;

}

#cadastroDeTools__modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cadastroDeTools__modal__container {

  background-color: #171717;
  text-align: center;
  
  position: relative;
  
  height: 80vh;
  width: 550px;

  border-radius: 10px;

  overflow: auto;

}

.close__modal__btn {
  position: absolute;
  background-color: red;
  top: 20px;
  right: 20px;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.3s;
  border: 1px solid black;
}

.close__modal__btn:hover {
  transform: scale(1.1);
}

#cadastroDeTools__section h2 {
  color: #f1f1f1;
  font-weight: 800;
}

#cadastroDeTools__section h3 {
  color: #f1f1f1;
  font-weight: 800;
  font-size: 2em;
}

#cadastroDeTools__modal h2 {
  color: #f1f1f1;
  font-weight: 800;
}

#cadastroDeTools__section p {
  color: #f1f1f1;
}

#cadastroDeTools__modal p {
  color: #f1f1f1;
}

#cadastroDeTools__form {
  /*Form*/
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s;
}

#cadastroDeTools__form > * {
  /*Form*/
  margin-top: 10px;
}

#cadastroDeTools__form label {
  /*Form*/
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;

  width: 100%;
}

#cadastroDeTools__form label > * {
  /*Form*/
  margin-top: 5px;
}

#cadastroDeTools__form button {
  width: 100%;
}

@media screen and (max-width: 1366px) {
  
  #cadastroDeTools__section h3 {
    font-size: 1.5em;
  }

}

@media screen and (max-width: 800px) {
  #cadastroDeTools__section {
    padding: 0 70px;
  }

  #cadastroDeTools__section h2 {
    text-align: center;
  }

  #cadastroDeTools__section h3 {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {

  .cadastroDeTools__modal__container {

    width: 90vw;

  }

  .close__modal__btn {
    
    top: 5px;
    
    right: 5px;
    
  }

}

@media screen and (max-width: 500px)
{
  .cadastroDeTools__container {

    width: 90vw;

  }
}