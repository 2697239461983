#sobrenos__main {
  flex-direction: column;
  align-items: center;
  background-color: #171717;
  color: #f1f1f1;
}

#quemSomos {
  width: 100%;
  min-height: 50vh;
  height: auto;

  justify-content: center;
  align-items: center;  
}

.quem-somos-item {
  width: 50%;
}

#criadores {
  width: 100%;
  min-height: 50vh;
  height: auto;

  flex-direction: column;
  align-items: center;
}

.criadores-container {
  width: 60vw;
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.criadores-container-item {
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
}

.criadores-container-item>* {
  margin: 3px 0;
}

.criadores-container-item p {
  font-size: 0.8em;
}

.criadores-container-item a {
  font-size: 0.9em;
  color: #f1f1f1;
  text-decoration: none;
}

.email:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

/* .picture {
  background-image: url("../imgs/braum-classic.png");
} */

.picture:hover {
  transform: rotateY(180deg);
  transition: all 0.5s;
}

.ar:hover .ariel {
  background-image: url("../imgs/My\ Picture.jpg");
  /* background-image: url("../imgs/Ariel\ Paixão\ dos\ Santos.jpg"); */
}

.ariel {
  padding: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid black;
  background-image: url("../imgs/braum-classic.png");

  transition: all 0.5s;
  -webkit-transition: background-image 0.4s ease-in-out;
  transition: background-image 0.4s ease-in-out;
}

.ma:hover .marcelo {
  background-image: url("../imgs/WhatsApp Image 2019-12-09 at 00.12.53.jpeg");
}

.odirlei {
  padding: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid black;

  transition: all 0.5s;
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
  background-image: url("../imgs/olaf-classic.png");
}

.odir:hover .odirlei {
  transition: all 0.5s;
  background-image: url("../imgs/delei.jfif");
}

.marcelo {
  padding: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid black;
  background-image: url("../imgs/MonkeyKing.jpg");

  transition: all 0.5s;
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
}

.marcelo:hover {
  background-image: url("../imgs/Marcelo_Sander.jpg");
  z-index: 2;
}

.picture:not(:hover) {
  transition: all 0.5s;
}

.picture img {
  height: 200px;
  border-radius: 50%;
  border: 5px solid black;
}

.img-link {
  padding: 92px 164px;
  border-radius: 7px;
}

.img-link:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

.img-link:not(:hover) {
  transition: all 0.3s;
}

#nossosPorfolios {
  width: 60vw;
  min-height: 20vh;
  height: auto;

  flex-direction: column;
  justify-content: center;
}

.port-container {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}

.port-container-item {
  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex: 1;
  /* Faz com que os elementos cresçam e se adaptem a viewport*/
  flex-basis: 370px;
  margin-bottom: 40px;
}

/* Backgrounds */
.ariel-port {
  background-image: url("../imgs/Portfólio\ Ariel.png");
  background-size: cover;
  background-position: center;
}

.marcelo-port {
  background-image: url("../imgs/Portfólio\ Marcelo.png");
  background-size: cover;
  background-position: center;
}

/* Classes Genéricas */
.center {
  text-align: center;
}

.left {
  text-align: left;
}

@media screen and (max-width: 1368px) {
  #nossosPorfolios {

    width: 90vw;
  
  }

  #quemSomos {

    margin-top: 15vh;

  }
}

@media screen and (max-width: 800px) {
  #quemSomos {
    padding: 40px 0;
  }

  #nossosPorfolios {
    width: 90vw;
  }

  .img-link {
    /* padding: 117px 229px; */
    padding: 19vh 33vw;
  }

  .quem-somos-item {
    width: 75%;
    text-align: justify;
  }
}

@media screen and (max-width: 500px) {
  .img-link {
    padding: 19vh 33vw;
  }

  .ma:focus .marcelo {
    background-image: url("../imgs/WhatsApp Image 2019-12-09 at 00.12.53.jpeg");
  }

  .ar:focus .ariel {
    background-image: url("../imgs/WhatsApp Image 2019-12-09 at 00.15.21.jpeg");
    /* background-image: url("../imgs/Ariel\ Paixão\ dos\ Santos.jpg"); */
  }
}