#splashScreen {
    width: 100%;
    height: 100vh;
    position: fixed;

    top: 0;
    left: 0;

    z-index: 3;

    background-color: black;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    animation: sway_left 2s normal forwards ease-out;
}

#splashScreen p {
    color: white;
    font-size: 2em;
}

#splashScreen img {
    height: 20vh;
}

@keyframes sway_left {
    from {left: 0;}
    50% {left: 0;}
    to {left: -100vw;}
}