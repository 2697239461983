.tools-container{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.ferramenta-item > * {
    margin: 20px 0px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}