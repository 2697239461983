/* introduçao */

main {
  flex-direction: column;
  align-items: center;
}

.main-home {
  /* padding-top: 40px; */
  min-height: 100vh;
  height: auto;
}

#welcome-home {
  background-image: url("../imgs/bgBlack.jpg");
  width: 100%;
  height: 100vh;
  text-align: center;
  /* background-image: url("../imgs/bgOptimized.jpg"); */
  background-size: cover;
  flex-direction: column;
  /* border-bottom: solid 3px blueviolet; */
  /* filter: blur(10px); */
  justify-content: center;
  /* align-items: center; */
  padding-bottom: 25px;

  position: relative;
}

#welcome-home img {
  /* height: 300px; */
  width: 50%;
}

#welcome-home p {
  padding: 10px 10px;
}

.welcome-home-logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .welcome-home-logo {
  width: 100%;
  height: 47vh;
  text-align: center;
  /* background-image: url("../imgs/bgOptimized.jpg"); */
/* background-size: cover; */
/* flex-direction: column; */
/* border-bottom: solid 3px blueviolet; */
/* filter: blur(10px); */
/* justify-content: flex-end; */
/* align-items: center; */
/* } */

.main-home h2 {
  text-align: center;
  color: #f1f1f1;
}

#oQueEOuref {
  background-color: #171717;
  min-height: 30vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#oQueEOuref p {
  color: #f1f1f1;
  text-align: left;
  font-size: 1.5em;
}

#oQueEOuref svg {
  color: #8600e5;
  font-size: 10em;
}

.oQueEOuref-itens {
  display: flex;
  align-items: center;
}

.oQueEOuref-itens > * {
  flex: 1;
}

.console-prompt {
  color: #8600e5;
  font-size: 15em;
  font-family: "Consolas";
  /* animation: consoleBlink 3s step-end infinite ease-in-out; */
  /* animation: move 5s infinite ease-in-out; */
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

/* teste */

.show {
  visibility: hidden;
}

.hide {
  visibility: visible;
}

.construindo {
  margin-top: 250px;
}

.construindo i {
  font-size: 3em;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@media screen and (max-width: 800px) {
  .console-prompt {
    display: none;
  }
  /* Tablets */
  .welcome-home img {
    height: unset;
    width: 90%;
  }

  .welcome-home-logo {
    height: 75vh;
    padding: 0 25px;
  }

  .main-home {
    padding-top: 0;
  }
}
