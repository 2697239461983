:root {

    --roxo :#8600e5;
    --cinza-escuro : #171717;

}

.not-visible {

    transform: translateX(100vw);

} 

.visible {

    transform: translateX(unset);

}

#hamburguerMenu {

    background-color: transparent;

    border: none;
    
    position: fixed;

    top: 5vh;

    right: 5vw;

    cursor: pointer;

    z-index: 2;

}

#hamburguerMenu svg {

    color: var(--roxo);
    
    font-size: 2.5em;

}

#mobileHeader {

    top: 0px;

    left: 0px;

    position: fixed;

    width: 100%;

    height: 100vh;

    background-color: var(--cinza-escuro);

    transition: all .5s ease-out;

    z-index: 3;

    display: flex;

    flex-direction: column;

    align-items: center;

}

#mobileHeader header {

    display: flex;

    align-items: center;

    justify-content: center;

    height: 20vh;

}

#mobileHeader .logo img {

    max-width: 30vw;

}

#mobileNavigation {

    width: 90%;

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;

}

.nav-item {
    
    list-style-type: none;

    text-align: center;

    padding: 20px;

}

.nav-item a {
    
    font-size: 2em;

    text-decoration: none;

    padding: 20px;

    color: #8600e5;

}

/* Footer */

#mobileHeader footer {
    
    position: fixed;
    
    bottom: 0px;
    
    padding: 40px;

    display: flex;

    justify-content: space-between;

    width: 100%;
    
}

.authentication-btns a {

    font-size: 1.5em;

    text-decoration: none;

    color: #8600e5;

}