:root {

  --ouref-main-purple: #8600e5;
  --ouref-mid-grey: #262626;
  --ouref-dark-grey: #171717;

}

/* Mobile Filter and Institutions */
#mobileFilter {

  height: 100vh;

  width: 100%;

  position: fixed;

  top: 0px;

  left: 0px;

  background-color: #171717;

  transition: all .5s ease-in-out;

  z-index: 2;

  display: flex;
  
  flex-direction: column;
  
  overflow-y: auto;

}

#mobileFilter .close-filter-icon {

  position: fixed;

  background-color: rgba(0, 0, 0, 0);

  border: none;

  position: fixed;

  right: 5vw;

  top: 5vh;

  cursor: pointer;

}

#mobileFilter .close-filter-icon svg {

  color: #8600e5;

  font-size: 2em;

}

#mobileFilterBtns {

  z-index: 2;

}

.filter-visible {

  transform: translateX(unset);

}

.filter-not-visible {

  transform: translateX(-100vw);

}

#mobileFilterBtns .filter-icon {

  background-color: rgba(0, 0, 0, 0);

  border: none;

  position: fixed;

  left: 5vw;

  top: 5vh;

  cursor: pointer;

}

#mobileFilterBtns .filter-icon svg {

  color: #8600e5;

  font-size: 2em;

}

#mobileFilter .categories {

  width: 100%;
  
  padding: 10vh 20px 0px 20px;

  text-align: center;

}

#mobileFilter .categories label {

  color: #8600e5;
  font-size: 1.5em;

}

#mobileFilter .categories-items {

  display: flex;

  flex-wrap: wrap;

  justify-content: center;
  
}

#mobileFilter .categories-items > * {

  margin: 10px;

}

#mobileFilter .institutions {

  width: 100%;
  
  text-align: center;

}

#mobileFilter .institutions ul li {

  list-style-type: none;

}

#mobileFilter .institutions button {
  
  font-size: 1.5em;

}

#mobileFilter .institutions ul > * {

  margin: 10px;

}

/* inicio conteudo */

#Tools__main {
  /* flex-wrap: wrap; */
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  background-color: #171717;
  /* background-color: #0A0A0A; */
  padding-left: 240px;
  /*Para não encostar no filtro*/
}

.sites-area {
  width: 100%;
  padding: 50px 20px;
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 8;
  /* margin-left: 10px; */
}

.ferramenta-item {
  width: 200px;
  min-height: 100px;
  height: auto;
  box-shadow: 3px 3px 3px rgb(44, 44, 44);
  margin: 20px;
  padding: 40px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262626;
  color: white;
  border-radius: 4px;

  transition: all 0.2s;
}

.ferramenta-item header {
  border-bottom: solid 2px #8600e5;
  /* border-bottom: solid 2px #f1f1f1; */
  padding: 2px 0px;
  text-align: center;

  transition: all 0.3s;

  margin-bottom: 10px;
  padding-bottom: 10px;
}

.ferramenta-item img {
  max-height: 120px;
  max-width: 145px;
}

.ferramenta-item header a {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 1.2em;
}

.ferramenta-item:hover {
  box-shadow: 5px 5px 7px #8600e5;
  transform: scale(1.07);
}

.ferramenta-item footer {
  height: 50px;

  display: flex;
  justify-content: center;
}

#filter {
 
  position: fixed;
  
  left: 20px;
  
  top: 8vh;
  
  height: 65vh;
  
}

#filterForm {
 
  height: 100%;
  
  display: flex;
  
  flex-direction: column;
  
  color: whitesmoke;

}

#filterForm > * {

  margin: 10px 0;

}

/* #filterForm label input {

  background-color: #fff;

} */

/* #filterForm label input:hover {

  background-color: blueviolet;

} */

/* .option-filter span {

  font-size: 1.1em;

} */

.option-filter > * {
  
  margin: 10px;

}

.option-filter span {

  font-size: 1.2em;

}

/* .ferramenta__Check[type="checkbox"] {

  display: none; */

  /* cursor: pointer; */

/* } */

/* .ferramenta__Check[type="checkbox"] + span::before {
  content: "\2714";
  border: 0.1em solid #141414;
  background-color: rgb(69, 69, 69);
  border-radius: 1em;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  cursor: pointer;
  justify-content: center;
}

.ferramenta__Check[type="checkbox"] + span:active:before {
  transform: scale(0);
}

.ferramenta__Check[type="checkbox"]:checked + span:before {
  background-color: #80f000;
  border-color: black;
  color: black;
}

.ferramenta__Check[type="checkbox"]:disabled + span:before {
  transform: scale(1);
  border-color: #aaa;
}

.ferramenta__Check[type="checkbox"]:checked:disabled + span:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
} */

/*Checkbox*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
      --active: var(--ouref-main-purple);
      --active-inner: #fff;
      --focus: 2px var(--ouref-main-purple);
      --border: var(--ouref-main-purple);
      --border-hover: var(--ouref-main-purple);
      --background: var(--ouref-mid-grey);
      --disabled: var(--ouref-mid-grey);
      --disabled-inner: var(--ouref-mid-grey);
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      -webkit-transition: background .3s, border-color .3s, box-shadow .2s;
      transition: background .3s, border-color .3s, box-shadow .2s;
  }

      input[type='checkbox']:after,
      input[type='radio']:after {
          content: '';
          display: block;
          left: 0;
          top: 0;
          position: absolute;
          -webkit-transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
          transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
      }

      input[type='checkbox']:checked,
      input[type='radio']:checked {
          --b: var(--active);
          --bc: var(--active);
          --d-o: .3s;
          --d-t: .6s;
          --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }

      input[type='checkbox']:disabled,
      input[type='radio']:disabled {
          --b: var(--disabled);
          cursor: not-allowed;
          opacity: .9;
      }

          input[type='checkbox']:disabled:checked,
          input[type='radio']:disabled:checked {
              --b: var(--disabled-inner);
              --bc: var(--border);
          }

          input[type='checkbox']:disabled + label,
          input[type='radio']:disabled + label {
              cursor: not-allowed;
          }

      input[type='checkbox']:hover:not(:checked):not(:disabled),
      input[type='radio']:hover:not(:checked):not(:disabled) {
          --bc: var(--border-hover);
      }

      input[type='checkbox']:focus,
      input[type='radio']:focus {
          box-shadow: 0 0 0 var(--focus);
      }

      input[type='checkbox']:not(.switch),
      input[type='radio']:not(.switch) {
          width: 21px;
      }

          input[type='checkbox']:not(.switch):after,
          input[type='radio']:not(.switch):after {
              opacity: var(--o, 0);
          }

          input[type='checkbox']:not(.switch):checked,
          input[type='radio']:not(.switch):checked {
              --o: 1;
          }

      input[type='checkbox'] + label,
      input[type='radio'] + label {
          font-size: 14px;
          line-height: 21px;
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
          margin-left: 4px;
      }

      input[type='checkbox']:not(.switch) {
          border-radius: 7px;
      }

          input[type='checkbox']:not(.switch):after {
              width: 5px;
              height: 9px;
              border: 2px solid var(--active-inner);
              border-top: 0;
              border-left: 0;
              left: 7px;
              top: 4px;
              -webkit-transform: rotate(var(--r, 20deg));
              transform: rotate(var(--r, 20deg));
          }

          input[type='checkbox']:not(.switch):checked {
              --r: 43deg;
          }

      input[type='checkbox'].switch {
          width: 38px;
          border-radius: 11px;
      }

          input[type='checkbox'].switch:after {
              left: 2px;
              top: 2px;
              border-radius: 50%;
              width: 15px;
              height: 15px;
              background: var(--ab, var(--border));
              -webkit-transform: translateX(var(--x, 0));
              transform: translateX(var(--x, 0));
          }

          input[type='checkbox'].switch:checked {
              --ab: var(--active-inner);
              --x: 17px;
          }

          input[type='checkbox'].switch:disabled:not(:checked):after {
              opacity: .6;
          }

  input[type='radio'] {
      border-radius: 50%;
  }

      input[type='radio']:after {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: var(--active-inner);
          opacity: 0;
          -webkit-transform: scale(var(--s, 0.7));
          transform: scale(var(--s, 0.7));
      }

      input[type='radio']:checked {
          --s: .5;
      }
}

#btnOpenFilter {
  display: none;
}

.text_fc_btn {
  font-size: 1em;
}

.addProposal-shortcut {

  position: absolute;

  top: 80px;

  right: 45px;

  display: flex;

  align-items: center;

}

.addProposal-shortcut > * {

  margin: 5px;

}

#institutions h2 {

  text-align: unset;

}

.user-credits {

  color: #707070;

  font-size: 1.2em;

}

.user-credits a {

  text-decoration: none;
  
  font-size: 1.25em !important;

  color: rgb(166, 70, 234);

}

@media screen and (max-width: 1366px)
{

    #filter {
   
      top: 12vh;
      
      height: 61vh;
      
    }
  
}

@media screen and (max-width: 800px) {
  /* Tablets */
  #Tools__main {
    padding-left: 0px;
    /*Para não encostar no filtro*/
    flex-direction: column;
  }

  /* #option-filter {
    display: none;
  } */

  #filterForm {
    background-color: #26262698;
    position: fixed;
    bottom: 0;
    height: auto;
    left: 0;
    flex-direction: row;
  }

  #btnOpenFilter {
    display: none;
    position: fixed;
    right: 5vh;
    bottom: 5vh;
  }

  #btnForm {
    display: none;
  }

  .text_filtors {
    display: none;
  }

  .ferramenta-item {
    /* min-width: 80vw; */
    min-height: 10vh;
    /* flex:1; */
    -webkit-align-items: center;
    align-items: center;
    width: 150px;
    /* width: auto; */
  }

  .ferramenta-item header a {
    text-decoration: none;
    font-size: 1em;
  }

  .ferramenta-item:hover {
    transform: scale(1) !important;
    box-shadow: 0px 0px 0px 0px black !important;
  }
}

@media screen and (max-width: 500px) {
  /* Phones */
  .ferramenta-item {
    width: 140px;
    height: auto;
    margin: 10px;
    align-items: center;
    /* width: auto; */
  }

  .sites-area {
    padding: 50px 5px;
  }

  .ferramenta-item header a {
    color: #eeeeee;
    text-decoration: none;
    font-size: 1em;
  }

  .ferramenta-item img {
    height: 10vh;
  }

  .pa-all-g {
    padding: 40px 0;
  }

  .ferramenta-item main {
    display: none;
  }

  .ferramenta-item footer {
    display: none;
  }

  .addProposal-shortcut {

    position: static !important;
  
    display: flex;
  
    align-items: center;
  
    margin-top: 40px;

  }
}
