body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  font-family: "K2D";
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
}

.hidden {

  opacity: 0;

}

/* Fontes */
@font-face {
  src: url(/static/media/K2D-ExtraLight.4d1a9c37.ttf),
    url(/static/media/K2D-ExtraLightItalic.4c5db2c0.ttf), url(/static/media/K2D-Italic.8457ee44.ttf),
    url(/static/media/K2D-Light.253992be.ttf), url(/static/media/K2D-LightItalic.b3e1311e.ttf),
    url(/static/media/K2D-Medium.1784ca83.ttf), url(/static/media/K2D-MediumItalic.93ca062e.ttf),
    url(/static/media/K2D-Regular.310069e7.ttf), url(/static/media/K2D-SemiBold.55bc9be8.ttf),
    url(/static/media/K2D-SemiBoldItalic.cbbb1989.ttf), url(/static/media/K2D-Thin.1791c38c.ttf),
    url(/static/media/K2D-ThinItalic.65bbb384.ttf);

  font-family: "K2D";
}

::selection {
  color: black;
  background: #8600e5;
}

/* DUAL BAR LOADER */
.loader-dual {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader-dual:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: loader-dual 1.2s linear infinite;
          animation: loader-dual 1.2s linear infinite;
}

@-webkit-keyframes loader-dual {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dual {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-logo img {

  height: 72px;

}

.loading-gif {

  display: flex;
  
  flex-direction: column;
  
  justify-content: center;
  
  align-items: center;

}

.loading-gif img {
  height: 75px;
}

.loading-gif p {
  color: #fff;
}

.container {
  width: 65%;
}

.flex-container {
  display: flex;
}

.menu-superior {
  
  position: fixed;
  height: 70px;
  width: 100%;
  z-index: 2;
  color: black;

  display: flex;
  transition: all 0.3s;

}

.info_container {

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 50px;

  border-radius: 4px;

  background-color: #262626;

}

.all-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
}

.menu-superior-color-after {
  background-color: #262626 !important;
  color: white !important;
}

.menu-superior-color-initial {
  background-color: white;
  color: black;
}

.color-black {
  color: black;
}

.color-white {
  color: white !important;
}

#menu-superior-content li {
  list-style-type: none;
  width: 180px;
}

.rounded-area-top {
  border-radius: 10px 10px 0 0;
}

.rounded-area-bottom {
  border-radius: 0 0 10px 10px;
}

.rounded-all {
  border-radius: 10px;
}

#menu-superior-content li:not(.loginButton):hover {
  background-color: #8600e5;
  transition: all 0.3s;
}

.loginButton {
  cursor: pointer;
  position: absolute;
  /* background-color: red; */

  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
}

.loginButton button {
  background-color: transparent;
  border: none;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginButton a {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginButton p {
  color: #8600e5;
  font-size: 1.25em;
  font-weight: bold;
}

.loginButton svg {
  font-size: 2em;
  color: #8600e5;
}

#cabecalho__button__toggle {
  display: none;
}

.main-item {
  display: flex;
  flex-direction: column;
}

.main-item-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-item-item a {
  font-size: 1.25em;
}

#menu-superior-content a {
  text-decoration: none;
  padding: 20px;
  text-align: center;
  color: black;
}

#menu-superior-content {
  z-index: 1;
  flex: 10 1;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 20%;
  transition: all .3s;
  /* background: #262626; */
}

#menu-superior-content li:hover #menu-superior-drop-down li {
  top: 0;
  display: flex;
  /* visibility: visible; */
  -webkit-animation: slide-down 0.3s ease-out;
          animation: slide-down 0.3s ease-out;
  /* pointer-events: all; */
}

#menu-superior-drop-down li:last-child {
  border-radius: 0px 0px 10px 10px;
}

#menu-superior-drop-down {
  position: relative;
  display: flex;
  flex-direction: column;
}

#menu-superior-drop-down a {
  font-size: 1.25em;
}

#menu-superior-drop-down li {
  display: none;
  width: 180px;
  background-color: #8600e5;
  justify-content: center;
  align-items: center;
}

.logo-ouref {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-ouref img {
  transition: all 0.3s;
  height: 52px;
}

.logo-ouref img:hover {
  transform: scale(1.1);
}

.empty {
  flex: 10 1;
}

/* Buttons */
.btn-purple {
  background-color: #8600e5;
  padding: 5px;
  /* height: 35px; */
  border-radius: 2px;
  text-decoration: none;
  color: white;
  border: none;
  box-shadow: 4px 4px 4px #262626;
  transition: all 0.3s;
  cursor: pointer;
}

.btn-purple:hover {
  box-shadow: 6px 6px 6px #262626;
}

.btn-purple-g {
  background-color: #8600e5;
  padding: 10px;
  font-size: 1.5em;
  /* height: 35px; */
  border-radius: 4px;
  text-decoration: none;
  color: white;
  border: none;
  box-shadow: 4px 4px 4px #262626;
  transition: all 0.3s;
  cursor: pointer;
}

.btn-purple-g:hover {
  box-shadow: 6px 6px 6px #262626;
  transform: scale(1.1);
}

.floating-purple-btn {
  
  height: auto;
  width: auto;

  min-width: 50px;
  min-height: 50px;

  background-color: #8600e5;
  color: #f1f1f1;
  
  position: fixed;
  left: 100px;
  bottom: 100px;

  border: none;
  border-radius: 8px;

  font-size: 2em;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .3s ease-out;

}

.floating-purple-btn:hover {

  transform:  scale(1.1)
              rotateZ(10deg);
            

}

.inpt-purple {
  width: 100%;
  border-radius: 5px;
  background-color: #171717;
  color: #f1f1f1;
  padding: 8px;
  font-size: 1.2em;
  font-family: "K2D";
  border: 1px solid #8600e5;

}

.goDownCircle {
  position: absolute;
  left: calc(50vw - 50px);
  bottom: 0px;

  /* background-color: #8600E5; */
  background-color: #171717;
  border-radius: 50%;
  height: 100px;
  width: 100px;

  display: flex;
  /* align-items: center; */
  justify-content: center;
  transform: translateY(50px);
}

.goDownCircle svg {
  margin-top: 12px;
  /* color: #171717; */
  color: #8600e5;
  font-size: 2em;
}

.contribuidor {
  font-size: 1.1em;
  color: #ccc;
}

.contribuidor a {
  font-size: 1.1em;
  color: #8600e5;
  text-decoration: none;
  margin-left: 10px;
}

.visible {
  /* Visible cabeçalho */
  transform: unset;
  z-index: 1;
}

.notVisible {
  /* Not visible cabeçalho*/
  transform: translateX(-100%);
  transition: all .5s ease-out;
  z-index: 1;
}

/* Animações */

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tipografia */
h2 {
  font-size: 2em;
  font-weight: 800;
}

h3 {
  color: #f1f1f1;
  font-size: 1.7em;
}

h4 {
  color: #f1f1f1;
  font-size: 1.5em;
}

p {
  font-size: 1.2em;
}

span {
  font-size: 1.5em;
}

.justify {
  text-align: justify;
}

/* Utils */
.highlighted-text
{

  color: #8600e5;

}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

/* Margens e paddings */
.ma-top-m {
  margin-top: 20px;
}

.ma-top-g {
  margin-top: 40px;
}

.pa-all-g {
  padding: 40px;
}
/* introduçao */

main {
  flex-direction: column;
  align-items: center;
}

.main-home {
  /* padding-top: 40px; */
  min-height: 100vh;
  height: auto;
}

#welcome-home {
  background-image: url(/static/media/bgBlack.4633455d.jpg);
  width: 100%;
  height: 100vh;
  text-align: center;
  /* background-image: url("../imgs/bgOptimized.jpg"); */
  background-size: cover;
  flex-direction: column;
  /* border-bottom: solid 3px blueviolet; */
  /* filter: blur(10px); */
  justify-content: center;
  /* align-items: center; */
  padding-bottom: 25px;

  position: relative;
}

#welcome-home img {
  /* height: 300px; */
  width: 50%;
}

#welcome-home p {
  padding: 10px 10px;
}

.welcome-home-logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .welcome-home-logo {
  width: 100%;
  height: 47vh;
  text-align: center;
  /* background-image: url("../imgs/bgOptimized.jpg"); */
/* background-size: cover; */
/* flex-direction: column; */
/* border-bottom: solid 3px blueviolet; */
/* filter: blur(10px); */
/* justify-content: flex-end; */
/* align-items: center; */
/* } */

.main-home h2 {
  text-align: center;
  color: #f1f1f1;
}

#oQueEOuref {
  background-color: #171717;
  min-height: 30vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#oQueEOuref p {
  color: #f1f1f1;
  text-align: left;
  font-size: 1.5em;
}

#oQueEOuref svg {
  color: #8600e5;
  font-size: 10em;
}

.oQueEOuref-itens {
  display: flex;
  align-items: center;
}

.oQueEOuref-itens > * {
  flex: 1 1;
}

.console-prompt {
  color: #8600e5;
  font-size: 15em;
  font-family: "Consolas";
  /* animation: consoleBlink 3s step-end infinite ease-in-out; */
  /* animation: move 5s infinite ease-in-out; */
  -webkit-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

/* teste */

.show {
  visibility: hidden;
}

.hide {
  visibility: visible;
}

.construindo {
  margin-top: 250px;
}

.construindo i {
  font-size: 3em;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #8600e5;
  }
}

@media screen and (max-width: 800px) {
  .console-prompt {
    display: none;
  }
  /* Tablets */
  .welcome-home img {
    height: unset;
    width: 90%;
  }

  .welcome-home-logo {
    height: 75vh;
    padding: 0 25px;
  }

  .main-home {
    padding-top: 0;
  }
}

:root {

    --roxo :#8600e5;
    --cinza-escuro : #171717;

}

.not-visible {

    transform: translateX(100vw);

} 

.visible {

    transform: translateX(unset);

}

#hamburguerMenu {

    background-color: transparent;

    border: none;
    
    position: fixed;

    top: 5vh;

    right: 5vw;

    cursor: pointer;

    z-index: 2;

}

#hamburguerMenu svg {

    color: #8600e5;

    color: var(--roxo);
    
    font-size: 2.5em;

}

#mobileHeader {

    top: 0px;

    left: 0px;

    position: fixed;

    width: 100%;

    height: 100vh;

    background-color: #171717;

    background-color: var(--cinza-escuro);

    transition: all .5s ease-out;

    z-index: 3;

    display: flex;

    flex-direction: column;

    align-items: center;

}

#mobileHeader header {

    display: flex;

    align-items: center;

    justify-content: center;

    height: 20vh;

}

#mobileHeader .logo img {

    max-width: 30vw;

}

#mobileNavigation {

    width: 90%;

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;

}

.nav-item {
    
    list-style-type: none;

    text-align: center;

    padding: 20px;

}

.nav-item a {
    
    font-size: 2em;

    text-decoration: none;

    padding: 20px;

    color: #8600e5;

}

/* Footer */

#mobileHeader footer {
    
    position: fixed;
    
    bottom: 0px;
    
    padding: 40px;

    display: flex;

    justify-content: space-between;

    width: 100%;
    
}

.authentication-btns a {

    font-size: 1.5em;

    text-decoration: none;

    color: #8600e5;

}
#ToolsDestaques {
  width: 100%;
  min-height: 40vh;
  background-color: #171717;
  height: auto;

  /* -webkit-box-shadow: 0px -2px 22px 3px rgba(204, 204, 204, 1); */
  /* -moz-box-shadow: 0px -2px 22px 3px rgba(204, 204, 204, 1); */
  /* box-shadow: 0px -2px 22px 3px rgb(53, 53, 53); */
}

.ToolsDestaques-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 0px 250px;
}

.maisToolsLink {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1284px) { /* Little Screens */
    .ToolsDestaques-container {
        padding: 0;
    }
}

@media screen and (max-width: 800px) { /* Tablets */
    .container {
        width: 85%;
    }

    .ToolsDestaques-container {
        /* flex-direction: column; */
        align-items: center;
    }

    .maisToolsLink {

        display: flex;

        justify-content: center;

    }

}

@media screen and (max-width: 800px) { /* Phones */
    .container {
        width: 100%;
    }

    .oQueEOuref-itens {
        flex-direction: column-reverse;
        padding: 0 30px;
      }

      #oQueEOuref p {
        text-align: center;
        margin-top: 40px;
      }

}


#splashScreen {
    width: 100%;
    height: 100vh;
    position: fixed;

    top: 0;
    left: 0;

    z-index: 3;

    background-color: black;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    -webkit-animation: sway_left 2s normal forwards ease-out;

            animation: sway_left 2s normal forwards ease-out;
}

#splashScreen p {
    color: white;
    font-size: 2em;
}

#splashScreen img {
    height: 20vh;
}

@-webkit-keyframes sway_left {
    from {left: 0;}
    50% {left: 0;}
    to {left: -100vw;}
}

@keyframes sway_left {
    from {left: 0;}
    50% {left: 0;}
    to {left: -100vw;}
}
#sobrenos__main {
  flex-direction: column;
  align-items: center;
  background-color: #171717;
  color: #f1f1f1;
}

#quemSomos {
  width: 100%;
  min-height: 50vh;
  height: auto;

  justify-content: center;
  align-items: center;  
}

.quem-somos-item {
  width: 50%;
}

#criadores {
  width: 100%;
  min-height: 50vh;
  height: auto;

  flex-direction: column;
  align-items: center;
}

.criadores-container {
  width: 60vw;
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.criadores-container-item {
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 1;
}

.criadores-container-item>* {
  margin: 3px 0;
}

.criadores-container-item p {
  font-size: 0.8em;
}

.criadores-container-item a {
  font-size: 0.9em;
  color: #f1f1f1;
  text-decoration: none;
}

.email:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

/* .picture {
  background-image: url("../imgs/braum-classic.png");
} */

.picture:hover {
  transform: rotateY(180deg);
  transition: all 0.5s;
}

.ar:hover .ariel {
  background-image: url("/static/media/My Picture.536f7360.jpg");
  /* background-image: url("../imgs/Ariel\ Paixão\ dos\ Santos.jpg"); */
}

.ariel {
  padding: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid black;
  background-image: url(/static/media/braum-classic.673d05a7.png);

  transition: all 0.5s;
  transition: background-image 0.4s ease-in-out;
}

.ma:hover .marcelo {
  background-image: url("/static/media/WhatsApp Image 2019-12-09 at 00.12.53.8dbed586.jpeg");
}

.odirlei {
  padding: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid black;

  transition: all 0.5s;
  transition: background-image 0.5s ease-in-out;
  background-image: url(/static/media/olaf-classic.3db055b9.png);
}

.odir:hover .odirlei {
  transition: all 0.5s;
  background-image: url(/static/media/delei.a6846f9e.jfif);
}

.marcelo {
  padding: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 5px solid black;
  background-image: url(/static/media/MonkeyKing.1a36bf1b.jpg);

  transition: all 0.5s;
  transition: background-image 0.5s ease-in-out;
}

.marcelo:hover {
  background-image: url(/static/media/Marcelo_Sander.ff8fa70e.jpg);
  z-index: 2;
}

.picture:not(:hover) {
  transition: all 0.5s;
}

.picture img {
  height: 200px;
  border-radius: 50%;
  border: 5px solid black;
}

.img-link {
  padding: 92px 164px;
  border-radius: 7px;
}

.img-link:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

.img-link:not(:hover) {
  transition: all 0.3s;
}

#nossosPorfolios {
  width: 60vw;
  min-height: 20vh;
  height: auto;

  flex-direction: column;
  justify-content: center;
}

.port-container {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}

.port-container-item {
  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex: 1 1;
  /* Faz com que os elementos cresçam e se adaptem a viewport*/
  flex-basis: 370px;
  margin-bottom: 40px;
}

/* Backgrounds */
.ariel-port {
  background-image: url("/static/media/Portfólio Ariel.8902c436.png");
  background-size: cover;
  background-position: center;
}

.marcelo-port {
  background-image: url("/static/media/Portfólio Marcelo.a72ed07b.png");
  background-size: cover;
  background-position: center;
}

/* Classes Genéricas */
.center {
  text-align: center;
}

.left {
  text-align: left;
}

@media screen and (max-width: 1368px) {
  #nossosPorfolios {

    width: 90vw;
  
  }

  #quemSomos {

    margin-top: 15vh;

  }
}

@media screen and (max-width: 800px) {
  #quemSomos {
    padding: 40px 0;
  }

  #nossosPorfolios {
    width: 90vw;
  }

  .img-link {
    /* padding: 117px 229px; */
    padding: 19vh 33vw;
  }

  .quem-somos-item {
    width: 75%;
    text-align: justify;
  }
}

@media screen and (max-width: 500px) {
  .img-link {
    padding: 19vh 33vw;
  }

  .ma:focus .marcelo {
    background-image: url("/static/media/WhatsApp Image 2019-12-09 at 00.12.53.8dbed586.jpeg");
  }

  .ar:focus .ariel {
    background-image: url("/static/media/WhatsApp Image 2019-12-09 at 00.15.21.eb4d259a.jpeg");
    /* background-image: url("../imgs/Ariel\ Paixão\ dos\ Santos.jpg"); */
  }
}
:root {

  --ouref-main-purple: #8600e5;
  --ouref-mid-grey: #262626;
  --ouref-dark-grey: #171717;

}

/* Mobile Filter and Institutions */
#mobileFilter {

  height: 100vh;

  width: 100%;

  position: fixed;

  top: 0px;

  left: 0px;

  background-color: #171717;

  transition: all .5s ease-in-out;

  z-index: 2;

  display: flex;
  
  flex-direction: column;
  
  overflow-y: auto;

}

#mobileFilter .close-filter-icon {

  position: fixed;

  background-color: rgba(0, 0, 0, 0);

  border: none;

  position: fixed;

  right: 5vw;

  top: 5vh;

  cursor: pointer;

}

#mobileFilter .close-filter-icon svg {

  color: #8600e5;

  font-size: 2em;

}

#mobileFilterBtns {

  z-index: 2;

}

.filter-visible {

  transform: translateX(unset);

}

.filter-not-visible {

  transform: translateX(-100vw);

}

#mobileFilterBtns .filter-icon {

  background-color: rgba(0, 0, 0, 0);

  border: none;

  position: fixed;

  left: 5vw;

  top: 5vh;

  cursor: pointer;

}

#mobileFilterBtns .filter-icon svg {

  color: #8600e5;

  font-size: 2em;

}

#mobileFilter .categories {

  width: 100%;
  
  padding: 10vh 20px 0px 20px;

  text-align: center;

}

#mobileFilter .categories label {

  color: #8600e5;
  font-size: 1.5em;

}

#mobileFilter .categories-items {

  display: flex;

  flex-wrap: wrap;

  justify-content: center;
  
}

#mobileFilter .categories-items > * {

  margin: 10px;

}

#mobileFilter .institutions {

  width: 100%;
  
  text-align: center;

}

#mobileFilter .institutions ul li {

  list-style-type: none;

}

#mobileFilter .institutions button {
  
  font-size: 1.5em;

}

#mobileFilter .institutions ul > * {

  margin: 10px;

}

/* inicio conteudo */

#Tools__main {
  /* flex-wrap: wrap; */
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  background-color: #171717;
  /* background-color: #0A0A0A; */
  padding-left: 240px;
  /*Para não encostar no filtro*/
}

.sites-area {
  width: 100%;
  padding: 50px 20px;
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 8 1;
  /* margin-left: 10px; */
}

.ferramenta-item {
  width: 200px;
  min-height: 100px;
  height: auto;
  box-shadow: 3px 3px 3px rgb(44, 44, 44);
  margin: 20px;
  padding: 40px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262626;
  color: white;
  border-radius: 4px;

  transition: all 0.2s;
}

.ferramenta-item header {
  border-bottom: solid 2px #8600e5;
  /* border-bottom: solid 2px #f1f1f1; */
  padding: 2px 0px;
  text-align: center;

  transition: all 0.3s;

  margin-bottom: 10px;
  padding-bottom: 10px;
}

.ferramenta-item img {
  max-height: 120px;
  max-width: 145px;
}

.ferramenta-item header a {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 1.2em;
}

.ferramenta-item:hover {
  box-shadow: 5px 5px 7px #8600e5;
  transform: scale(1.07);
}

.ferramenta-item footer {
  height: 50px;

  display: flex;
  justify-content: center;
}

#filter {
 
  position: fixed;
  
  left: 20px;
  
  top: 8vh;
  
  height: 65vh;
  
}

#filterForm {
 
  height: 100%;
  
  display: flex;
  
  flex-direction: column;
  
  color: whitesmoke;

}

#filterForm > * {

  margin: 10px 0;

}

/* #filterForm label input {

  background-color: #fff;

} */

/* #filterForm label input:hover {

  background-color: blueviolet;

} */

/* .option-filter span {

  font-size: 1.1em;

} */

.option-filter > * {
  
  margin: 10px;

}

.option-filter span {

  font-size: 1.2em;

}

/* .ferramenta__Check[type="checkbox"] {

  display: none; */

  /* cursor: pointer; */

/* } */

/* .ferramenta__Check[type="checkbox"] + span::before {
  content: "\2714";
  border: 0.1em solid #141414;
  background-color: rgb(69, 69, 69);
  border-radius: 1em;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  cursor: pointer;
  justify-content: center;
}

.ferramenta__Check[type="checkbox"] + span:active:before {
  transform: scale(0);
}

.ferramenta__Check[type="checkbox"]:checked + span:before {
  background-color: #80f000;
  border-color: black;
  color: black;
}

.ferramenta__Check[type="checkbox"]:disabled + span:before {
  transform: scale(1);
  border-color: #aaa;
}

.ferramenta__Check[type="checkbox"]:checked:disabled + span:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
} */

/*Checkbox*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
      --active: var(--ouref-main-purple);
      --active-inner: #fff;
      --focus: 2px var(--ouref-main-purple);
      --border: var(--ouref-main-purple);
      --border-hover: var(--ouref-main-purple);
      --background: var(--ouref-mid-grey);
      --disabled: var(--ouref-mid-grey);
      --disabled-inner: var(--ouref-mid-grey);
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--border);
      border: 1px solid var(--bc, var(--border));
      background: var(--background);
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
  }

      input[type='checkbox']:after,
      input[type='radio']:after {
          content: '';
          display: block;
          left: 0;
          top: 0;
          position: absolute;
          transition: transform 0.3s ease, opacity 0.2s;
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
      }

      input[type='checkbox']:checked,
      input[type='radio']:checked {
          --b: var(--active);
          --bc: var(--active);
          --d-o: .3s;
          --d-t: .6s;
          --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }

      input[type='checkbox']:disabled,
      input[type='radio']:disabled {
          --b: var(--disabled);
          cursor: not-allowed;
          opacity: .9;
      }

          input[type='checkbox']:disabled:checked,
          input[type='radio']:disabled:checked {
              --b: var(--disabled-inner);
              --bc: var(--border);
          }

          input[type='checkbox']:disabled + label,
          input[type='radio']:disabled + label {
              cursor: not-allowed;
          }

      input[type='checkbox']:hover:not(:checked):not(:disabled),
      input[type='radio']:hover:not(:checked):not(:disabled) {
          --bc: var(--border-hover);
      }

      input[type='checkbox']:focus,
      input[type='radio']:focus {
          box-shadow: 0 0 0 var(--focus);
      }

      input[type='checkbox']:not(.switch),
      input[type='radio']:not(.switch) {
          width: 21px;
      }

          input[type='checkbox']:not(.switch):after,
          input[type='radio']:not(.switch):after {
              opacity: 0;
              opacity: var(--o, 0);
          }

          input[type='checkbox']:not(.switch):checked,
          input[type='radio']:not(.switch):checked {
              --o: 1;
          }

      input[type='checkbox'] + label,
      input[type='radio'] + label {
          font-size: 14px;
          line-height: 21px;
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
          margin-left: 4px;
      }

      input[type='checkbox']:not(.switch) {
          border-radius: 7px;
      }

          input[type='checkbox']:not(.switch):after {
              width: 5px;
              height: 9px;
              border: 2px solid var(--active-inner);
              border-top: 0;
              border-left: 0;
              left: 7px;
              top: 4px;
              transform: rotate(20deg);
              transform: rotate(var(--r, 20deg));
          }

          input[type='checkbox']:not(.switch):checked {
              --r: 43deg;
          }

      input[type='checkbox'].switch {
          width: 38px;
          border-radius: 11px;
      }

          input[type='checkbox'].switch:after {
              left: 2px;
              top: 2px;
              border-radius: 50%;
              width: 15px;
              height: 15px;
              background: var(--border);
              background: var(--ab, var(--border));
              transform: translateX(0);
              transform: translateX(var(--x, 0));
          }

          input[type='checkbox'].switch:checked {
              --ab: var(--active-inner);
              --x: 17px;
          }

          input[type='checkbox'].switch:disabled:not(:checked):after {
              opacity: .6;
          }

  input[type='radio'] {
      border-radius: 50%;
  }

      input[type='radio']:after {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: var(--active-inner);
          opacity: 0;
          transform: scale(0.7);
          transform: scale(var(--s, 0.7));
      }

      input[type='radio']:checked {
          --s: .5;
      }
}

#btnOpenFilter {
  display: none;
}

.text_fc_btn {
  font-size: 1em;
}

.addProposal-shortcut {

  position: absolute;

  top: 80px;

  right: 45px;

  display: flex;

  align-items: center;

}

.addProposal-shortcut > * {

  margin: 5px;

}

#institutions h2 {

  text-align: unset;

}

.user-credits {

  color: #707070;

  font-size: 1.2em;

}

.user-credits a {

  text-decoration: none;
  
  font-size: 1.25em !important;

  color: rgb(166, 70, 234);

}

@media screen and (max-width: 1366px)
{

    #filter {
   
      top: 12vh;
      
      height: 61vh;
      
    }
  
}

@media screen and (max-width: 800px) {
  /* Tablets */
  #Tools__main {
    padding-left: 0px;
    /*Para não encostar no filtro*/
    flex-direction: column;
  }

  /* #option-filter {
    display: none;
  } */

  #filterForm {
    background-color: #26262698;
    position: fixed;
    bottom: 0;
    height: auto;
    left: 0;
    flex-direction: row;
  }

  #btnOpenFilter {
    display: none;
    position: fixed;
    right: 5vh;
    bottom: 5vh;
  }

  #btnForm {
    display: none;
  }

  .text_filtors {
    display: none;
  }

  .ferramenta-item {
    /* min-width: 80vw; */
    min-height: 10vh;
    /* flex:1; */
    align-items: center;
    width: 150px;
    /* width: auto; */
  }

  .ferramenta-item header a {
    text-decoration: none;
    font-size: 1em;
  }

  .ferramenta-item:hover {
    transform: scale(1) !important;
    box-shadow: 0px 0px 0px 0px black !important;
  }
}

@media screen and (max-width: 500px) {
  /* Phones */
  .ferramenta-item {
    width: 140px;
    height: auto;
    margin: 10px;
    align-items: center;
    /* width: auto; */
  }

  .sites-area {
    padding: 50px 5px;
  }

  .ferramenta-item header a {
    color: #eeeeee;
    text-decoration: none;
    font-size: 1em;
  }

  .ferramenta-item img {
    height: 10vh;
  }

  .pa-all-g {
    padding: 40px 0;
  }

  .ferramenta-item main {
    display: none;
  }

  .ferramenta-item footer {
    display: none;
  }

  .addProposal-shortcut {

    position: static !important;
  
    display: flex;
  
    align-items: center;
  
    margin-top: 40px;

  }
}

#cadastroDeTools__section {
  min-height: 20vh;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cadastroDeTools__container {

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 50px;
  padding-right: 50px;
  border-radius: 4px;

  background-color: #262626;

}

#cadastroDeTools__modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cadastroDeTools__modal__container {

  background-color: #171717;
  text-align: center;
  
  position: relative;
  
  height: 80vh;
  width: 550px;

  border-radius: 10px;

  overflow: auto;

}

.close__modal__btn {
  position: absolute;
  background-color: red;
  top: 20px;
  right: 20px;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.3s;
  border: 1px solid black;
}

.close__modal__btn:hover {
  transform: scale(1.1);
}

#cadastroDeTools__section h2 {
  color: #f1f1f1;
  font-weight: 800;
}

#cadastroDeTools__section h3 {
  color: #f1f1f1;
  font-weight: 800;
  font-size: 2em;
}

#cadastroDeTools__modal h2 {
  color: #f1f1f1;
  font-weight: 800;
}

#cadastroDeTools__section p {
  color: #f1f1f1;
}

#cadastroDeTools__modal p {
  color: #f1f1f1;
}

#cadastroDeTools__form {
  /*Form*/
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s;
}

#cadastroDeTools__form > * {
  /*Form*/
  margin-top: 10px;
}

#cadastroDeTools__form label {
  /*Form*/
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;

  width: 100%;
}

#cadastroDeTools__form label > * {
  /*Form*/
  margin-top: 5px;
}

#cadastroDeTools__form button {
  width: 100%;
}

@media screen and (max-width: 1366px) {
  
  #cadastroDeTools__section h3 {
    font-size: 1.5em;
  }

}

@media screen and (max-width: 800px) {
  #cadastroDeTools__section {
    padding: 0 70px;
  }

  #cadastroDeTools__section h2 {
    text-align: center;
  }

  #cadastroDeTools__section h3 {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {

  .cadastroDeTools__modal__container {

    width: 90vw;

  }

  .close__modal__btn {
    
    top: 5px;
    
    right: 5px;
    
  }

}

@media screen and (max-width: 500px)
{
  .cadastroDeTools__container {

    width: 90vw;

  }
}
#login__container {
    width: 100%;
    height: 100vh;
    background-color: #171717;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 40px;
}

#login__container p {
    color: white;
}

#login__form {
    /*Form*/
    height: 500px;
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all .3s;
  }
  
  #login__form > * {
    /*Form*/
    margin-top: 10px;
  }
  
  #login__form label {
    /*Form*/
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
  
    width: 100%;
  }
  
  #login__form label > * {
    /*Form*/
    margin-top: 5px;
  }
  #login__form button {
    width: 100%;
  }

  h1 {
      color: white;
      font-size: 5em;
  }

  @media screen and (max-width: 1366px)
  {
    h1 {
      margin-top: 10vh;
    }
  }

  @media screen and (max-width: 800px)
  {
    #login__form {
      width: 80%;
    }
  }
html {

    scroll-behavior: smooth;

}

.page-container {
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: #171717;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-area {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;
}

.title-area>* {
    margin: 20px;
}

.page-container p {
    color: white;
}

h1 {
    color: white;
    font-size: 5em;
}

/* OUREF SECTION */
.ouref-section {
    padding: 50px;
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

/* OUREF FORM */
.ouref-form {
    margin: 20px;
}

.ouref-form > * {
    margin: 10px;
}

/* OUREF TABLE */

.ouref-table {
    margin: 20px;
    width: 100%;
}

.ouref-table thead {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ouref-table tbody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.ouref-table th {
    color: #8600e5;
    font-size: 1.8em;
    margin: 0px 20px;
}

.ouref-table tr {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
}

.ouref-table td {
    color: #8600e5;
    font-size: 1.5em;
    margin: 0px 20px;
    
    text-align: center;
    flex: 1 1;
}

/* OUREF LIST */
.ouref-list > *{
    margin: 10px;
} 

.ouref-list li {

    list-style-type: none;

    color: #f1f1f1;

} 

/* Items */
.clickable {
    cursor: pointer;
}

.list-btn {

    background-color: rgba(0, 0, 0, 0);

    color: #A646EA;

    border: none;

    padding : 5px;

    font-size: 1.3em;

}

.clicked {

    background-color: rgba(0, 0, 0, 0);

    color: #A646EA;

    border: 1px solid #8600e5;

    padding : 5px;

    font-size: 1.3em;

}

.flex-around {
    
    display: flex;

    justify-content: space-around;

    align-items: center;

    width: 80vw;

}

@media screen and (max-width: 1366px) {
  .title-area {
    height: 35vh
  }
}

@media screen and (max-width: 500px) {
  
    .title-area {
        height: 35vh
    }
    
    .flex-around {
    
        width: 100vw;

        flex-wrap: wrap;
    
    }
}
* {
  font-size: 14px;
}

#propstaTools__main {
  background-color: #171717;
  min-height: 100vh;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.propostasSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.propostaTools__lista__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propostaTools__lista__container span {
  font-size: 2em;
  text-align: center;
  color: #8600e5;
}

.propostaTools__list__item {
  background-color: rgb(44, 44, 44);
  padding: 20px;
  margin: 20px;
  width: 60vw;
  border-radius: 5px;
  word-break: break-word;
}

.propostaTools__list__item p {
  color: #ccc;
  font-size: 2em;
}

.propostaTools__list__item a {
  font-size: 1em;
  color: #ccc;
}

.propostaTools__btns {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #8600e5;
  display: flex;
  justify-content: space-around;
}

.green-btn {
  color: #ccc;
  background-color: green;
  border: 1px solid black;
  border-radius: 5px;
}

.btn {
  padding: 10px 20px;
  font-size: 1.5em;
  border-radius: 5px;
}

.red-btn {
  color: #ccc;
  background-color: brown;
  border: 1px solid black;
}

h2 {
  text-align: center;
  color: #ccc;
  font-size: 3em;
}

@media screen and (max-width: 1366px) {
  .propostaTools__list__item p {
    font-size: 1.5em;
  }

  h2 {
    font-size: 2em;
  }
}

@media screen and (max-width: 500px) {

  .propostaTools__list__item {
    width: 90vw;
    word-break: break-all;
  }

}
.dashboard-links {

  display: flex;

  right: 10px;

}

.dashboard-links > * {

  margin: 10px;

}

@media screen and (max-width: 1366px) {
 
}

@media screen and (max-width: 500px) {
 
  .dashboard-links {

    display: flex;
  
    right: 10px;
  
    width: 100%;
    
    flex-wrap: wrap;
    
    justify-content: center;
  
  }

}

.tools-container{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.ferramenta-item > * {
    margin: 20px 0px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}
