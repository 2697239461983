* {
  font-size: 14px;
}

#propstaTools__main {
  background-color: #171717;
  min-height: 100vh;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.propostasSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.propostaTools__lista__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propostaTools__lista__container span {
  font-size: 2em;
  text-align: center;
  color: #8600e5;
}

.propostaTools__list__item {
  background-color: rgb(44, 44, 44);
  padding: 20px;
  margin: 20px;
  width: 60vw;
  border-radius: 5px;
  word-break: break-word;
}

.propostaTools__list__item p {
  color: #ccc;
  font-size: 2em;
}

.propostaTools__list__item a {
  font-size: 1em;
  color: #ccc;
}

.propostaTools__btns {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #8600e5;
  display: flex;
  justify-content: space-around;
}

.green-btn {
  color: #ccc;
  background-color: green;
  border: 1px solid black;
  border-radius: 5px;
}

.btn {
  padding: 10px 20px;
  font-size: 1.5em;
  border-radius: 5px;
}

.red-btn {
  color: #ccc;
  background-color: brown;
  border: 1px solid black;
}

h2 {
  text-align: center;
  color: #ccc;
  font-size: 3em;
}

@media screen and (max-width: 1366px) {
  .propostaTools__list__item p {
    font-size: 1.5em;
  }

  h2 {
    font-size: 2em;
  }
}

@media screen and (max-width: 500px) {

  .propostaTools__list__item {
    width: 90vw;
    word-break: break-all;
  }

}