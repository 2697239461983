#ToolsDestaques {
  width: 100%;
  min-height: 40vh;
  background-color: #171717;
  height: auto;

  /* -webkit-box-shadow: 0px -2px 22px 3px rgba(204, 204, 204, 1); */
  /* -moz-box-shadow: 0px -2px 22px 3px rgba(204, 204, 204, 1); */
  /* box-shadow: 0px -2px 22px 3px rgb(53, 53, 53); */
}

.ToolsDestaques-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 0px 250px;
}

.maisToolsLink {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1284px) { /* Little Screens */
    .ToolsDestaques-container {
        padding: 0;
    }
}

@media screen and (max-width: 800px) { /* Tablets */
    .container {
        width: 85%;
    }

    .ToolsDestaques-container {
        /* flex-direction: column; */
        align-items: center;
    }

    .maisToolsLink {

        display: flex;

        justify-content: center;

    }

}

@media screen and (max-width: 800px) { /* Phones */
    .container {
        width: 100%;
    }

    .oQueEOuref-itens {
        flex-direction: column-reverse;
        padding: 0 30px;
      }

      #oQueEOuref p {
        text-align: center;
        margin-top: 40px;
      }

}

