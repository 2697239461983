html {

    scroll-behavior: smooth;

}

.page-container {
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: #171717;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-area {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;
}

.title-area>* {
    margin: 20px;
}

.page-container p {
    color: white;
}

h1 {
    color: white;
    font-size: 5em;
}

/* OUREF SECTION */
.ouref-section {
    padding: 50px;
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

/* OUREF FORM */
.ouref-form {
    margin: 20px;
}

.ouref-form > * {
    margin: 10px;
}

/* OUREF TABLE */

.ouref-table {
    margin: 20px;
    width: 100%;
}

.ouref-table thead {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ouref-table tbody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.ouref-table th {
    color: #8600e5;
    font-size: 1.8em;
    margin: 0px 20px;
}

.ouref-table tr {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
}

.ouref-table td {
    color: #8600e5;
    font-size: 1.5em;
    margin: 0px 20px;
    
    text-align: center;
    flex: 1;
}

/* OUREF LIST */
.ouref-list > *{
    margin: 10px;
} 

.ouref-list li {

    list-style-type: none;

    color: #f1f1f1;

} 

/* Items */
.clickable {
    cursor: pointer;
}

.list-btn {

    background-color: rgba(0, 0, 0, 0);

    color: #A646EA;

    border: none;

    padding : 5px;

    font-size: 1.3em;

}

.clicked {

    background-color: rgba(0, 0, 0, 0);

    color: #A646EA;

    border: 1px solid #8600e5;

    padding : 5px;

    font-size: 1.3em;

}

.flex-around {
    
    display: flex;

    justify-content: space-around;

    align-items: center;

    width: 80vw;

}

@media screen and (max-width: 1366px) {
  .title-area {
    height: 35vh
  }
}

@media screen and (max-width: 500px) {
  
    .title-area {
        height: 35vh
    }
    
    .flex-around {
    
        width: 100vw;

        flex-wrap: wrap;
    
    }
}