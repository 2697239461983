* {
  padding: 0;
  margin: 0;
  font-family: "K2D";
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
}

.hidden {

  opacity: 0;

}

/* Fontes */
@font-face {
  src: url("../fonts/K2D-ExtraLight.ttf"),
    url("../fonts/K2D-ExtraLightItalic.ttf"), url("../fonts/K2D-Italic.ttf"),
    url("../fonts/K2D-Light.ttf"), url("../fonts/K2D-LightItalic.ttf"),
    url("../fonts/K2D-Medium.ttf"), url("../fonts/K2D-MediumItalic.ttf"),
    url("../fonts/K2D-Regular.ttf"), url("../fonts/K2D-SemiBold.ttf"),
    url("../fonts/K2D-SemiBoldItalic.ttf"), url("../fonts/K2D-Thin.ttf"),
    url("../fonts/K2D-ThinItalic.ttf");

  font-family: "K2D";
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #8600e5;
}

::selection {
  color: black;
  background: #8600e5;
}

/* DUAL BAR LOADER */
.loader-dual {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader-dual:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader-dual 1.2s linear infinite;
}

@keyframes loader-dual {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-logo img {

  height: 72px;

}

.loading-gif {

  display: flex;
  
  flex-direction: column;
  
  justify-content: center;
  
  align-items: center;

}

.loading-gif img {
  height: 75px;
}

.loading-gif p {
  color: #fff;
}

.container {
  width: 65%;
}

.flex-container {
  display: flex;
}

.menu-superior {
  
  position: fixed;
  height: 70px;
  width: 100%;
  z-index: 2;
  color: black;

  display: flex;
  transition: all 0.3s;

}

.info_container {

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 50px;

  border-radius: 4px;

  background-color: #262626;

}

.all-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
}

.menu-superior-color-after {
  background-color: #262626 !important;
  color: white !important;
}

.menu-superior-color-initial {
  background-color: white;
  color: black;
}

.color-black {
  color: black;
}

.color-white {
  color: white !important;
}

#menu-superior-content li {
  list-style-type: none;
  width: 180px;
}

.rounded-area-top {
  border-radius: 10px 10px 0 0;
}

.rounded-area-bottom {
  border-radius: 0 0 10px 10px;
}

.rounded-all {
  border-radius: 10px;
}

#menu-superior-content li:not(.loginButton):hover {
  background-color: #8600e5;
  transition: all 0.3s;
}

.loginButton {
  cursor: pointer;
  position: absolute;
  /* background-color: red; */

  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
}

.loginButton button {
  background-color: transparent;
  border: none;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginButton a {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginButton p {
  color: #8600e5;
  font-size: 1.25em;
  font-weight: bold;
}

.loginButton svg {
  font-size: 2em;
  color: #8600e5;
}

#cabecalho__button__toggle {
  display: none;
}

.main-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.main-item-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-item-item a {
  font-size: 1.25em;
}

#menu-superior-content a {
  text-decoration: none;
  padding: 20px;
  text-align: center;
  color: black;
}

#menu-superior-content {
  z-index: 1;
  flex: 10;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 20%;
  transition: all .3s;
  /* background: #262626; */
}

#menu-superior-content li:hover #menu-superior-drop-down li {
  top: 0;
  display: flex;
  /* visibility: visible; */
  animation: slide-down 0.3s ease-out;
  /* pointer-events: all; */
}

#menu-superior-drop-down li:last-child {
  border-radius: 0px 0px 10px 10px;
}

#menu-superior-drop-down {
  position: relative;
  display: flex;
  flex-direction: column;
}

#menu-superior-drop-down a {
  font-size: 1.25em;
}

#menu-superior-drop-down li {
  display: none;
  width: 180px;
  background-color: #8600e5;
  justify-content: center;
  align-items: center;
}

.logo-ouref {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-ouref img {
  transition: all 0.3s;
  height: 52px;
}

.logo-ouref img:hover {
  transform: scale(1.1);
}

.empty {
  flex: 10;
}

/* Buttons */
.btn-purple {
  background-color: #8600e5;
  padding: 5px;
  /* height: 35px; */
  border-radius: 2px;
  text-decoration: none;
  color: white;
  border: none;
  box-shadow: 4px 4px 4px #262626;
  transition: all 0.3s;
  cursor: pointer;
}

.btn-purple:hover {
  box-shadow: 6px 6px 6px #262626;
}

.btn-purple-g {
  background-color: #8600e5;
  padding: 10px;
  font-size: 1.5em;
  /* height: 35px; */
  border-radius: 4px;
  text-decoration: none;
  color: white;
  border: none;
  box-shadow: 4px 4px 4px #262626;
  transition: all 0.3s;
  cursor: pointer;
}

.btn-purple-g:hover {
  box-shadow: 6px 6px 6px #262626;
  transform: scale(1.1);
}

.floating-purple-btn {
  
  height: auto;
  width: auto;

  min-width: 50px;
  min-height: 50px;

  background-color: #8600e5;
  color: #f1f1f1;
  
  position: fixed;
  left: 100px;
  bottom: 100px;

  border: none;
  border-radius: 8px;

  font-size: 2em;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .3s ease-out;

}

.floating-purple-btn:hover {

  transform:  scale(1.1)
              rotateZ(10deg);
            

}

.inpt-purple {
  width: 100%;
  border-radius: 5px;
  background-color: #171717;
  color: #f1f1f1;
  padding: 8px;
  font-size: 1.2em;
  font-family: "K2D";
  border: 1px solid #8600e5;

}

.goDownCircle {
  position: absolute;
  left: calc(50vw - 50px);
  bottom: 0px;

  /* background-color: #8600E5; */
  background-color: #171717;
  border-radius: 50%;
  height: 100px;
  width: 100px;

  display: flex;
  /* align-items: center; */
  justify-content: center;
  transform: translateY(50px);
}

.goDownCircle svg {
  margin-top: 12px;
  /* color: #171717; */
  color: #8600e5;
  font-size: 2em;
}

.contribuidor {
  font-size: 1.1em;
  color: #ccc;
}

.contribuidor a {
  font-size: 1.1em;
  color: #8600e5;
  text-decoration: none;
  margin-left: 10px;
}

.visible {
  /* Visible cabeçalho */
  transform: unset;
  z-index: 1;
}

.notVisible {
  /* Not visible cabeçalho*/
  transform: translateX(-100%);
  transition: all .5s ease-out;
  z-index: 1;
}

/* Animações */

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tipografia */
h2 {
  font-size: 2em;
  font-weight: 800;
}

h3 {
  color: #f1f1f1;
  font-size: 1.7em;
}

h4 {
  color: #f1f1f1;
  font-size: 1.5em;
}

p {
  font-size: 1.2em;
}

span {
  font-size: 1.5em;
}

.justify {
  text-align: justify;
}

/* Utils */
.highlighted-text
{

  color: #8600e5;

}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

/* Margens e paddings */
.ma-top-m {
  margin-top: 20px;
}

.ma-top-g {
  margin-top: 40px;
}

.pa-all-g {
  padding: 40px;
}