.dashboard-links {

  display: flex;

  right: 10px;

}

.dashboard-links > * {

  margin: 10px;

}

@media screen and (max-width: 1366px) {
 
}

@media screen and (max-width: 500px) {
 
  .dashboard-links {

    display: flex;
  
    right: 10px;
  
    width: 100%;
    
    flex-wrap: wrap;
    
    justify-content: center;
  
  }

}
